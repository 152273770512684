import React, { useState,useEffect } from 'react';
import { Link, Route,Routes} from 'react-router-dom';
import Prescription from './Medicine';
import axios from 'axios';
import Api from "../Api/Api.json";
import BottomTab from './BottomTab';
import SidebarNav from './SidebarNav';

const items = ['Item1', 'Item2', 'Item3'];

const CreatedVideoList = () => {
  //const prescription_list=[];
  const [video_list,setVideoList]=useState([]);
  var headers=null;
  const base_url = Api.MAGICVERSE_USER_API;
  useEffect(()=>{
    const fetchAuthVideos = async () => {
      const auth_user = await localStorage.getItem('auth_user');
      const id_token = JSON.parse(auth_user)?.id_token;
      headers = {
          Authorization: `Bearer ${id_token}`,
          "Content-Type": "application/json",
        };

        const body = {
          task: "getCompletedVideoList",
        };
        try {
          const response = await fetch(base_url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }  
          const data = await response.json();
          console.log(data);
          const arr=[];
          data.forEach(obj=>{
            arr.push(obj['topic']+','+obj['created_on'])
          });
          setVideoList(arr);
        } catch (err) {
            console.log(err.message);
          }
        /*
        const url = Api.MAGICVERSE_USER_API;
        const requestData = {
          task: 'getCompletedVideoList'
        };
        console.log(headers);
        axios.post(url,requestData,headers)
        .then(response=>{
          if (response.data.length > 0){
            const arr=[];
        //onLogin(username);
        console.log(response.data[0])
        response.data[0].forEach(
            obj=>{
              arr.push(obj['topic']+','+obj['created_on'])
            }
          );
          setVideoList(arr)
          }
        })
        .catch(error=>{
          console.log(error)
        });
        */    
    };
    
    fetchAuthVideos();
  },[]);

  const handleDownloadVideo = async(date) => {
    console.log(date);
    const auth_user = await localStorage.getItem('auth_user');
    const id_token = JSON.parse(auth_user)?.id_token;
    headers = {
        Authorization: `Bearer ${id_token}`,
        "Content-Type": "application/json",
      };

      try {
        const body={
          task:'getVideoDownloadLink',
          date:date
        }
        console.log(headers);
        const response = await fetch(base_url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }  
        const presigned_url = await response.json();
        console.log(presigned_url);
        if (presigned_url) {
          const link = document.createElement("a");
          link.href = presigned_url; // Set the presigned URL
          link.download = ""; // Optional: Set a custom filename if needed
          link.target = "_blank"; // Opens in a new tab, ensures smooth experience
          document.body.appendChild(link); // Append the <a> to the DOM
          link.click(); // Programmatically trigger the click
          document.body.removeChild(link); // Clean up the DOM
          }        

      } catch (err) {
            console.log(err.message);
        }


    };

    return (
        <div flex-grow="1">
          <div>
          <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
          <h1>List of Created Videos (Click link to download).</h1>
          <ul>
            {video_list.map((item, index) => (
              <li key={index}>
                <button onClick={()=>handleDownloadVideo(item.split(',')[1])}>{item}</button>
              </li>
            ))}
          </ul>
          </div>
          <SidebarNav />
          {/* Nested Route for Item Details */}
          </div>
        </div>
      );
    };


export default CreatedVideoList;