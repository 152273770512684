import React, { useState,useEffect } from 'react';
import SidebarNav from './SidebarNav';
const Support = () => {
  const [description, setDescription] = useState('Write a brief message');
  

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };


  const handleSubmitSupportMessage = async() => {
    //call api and show message on successful submission.
    const auth_user = await localStorage.getItem('auth_user');
    const id_token = JSON.parse(auth_user)?.id_token;
    const headers = {
        Authorization: `Bearer ${id_token}`,
        "Content-Type": "application/json",
      };
      //write code for counting words in generatedScript
      //console.log(generatedScript);

      const num_words = description.length;
      //console.log(topic_char_count);
      //console.log(num_words);
      if (num_words > 170)
      {
        alert("Shorten the message (<170 words.). We'll get back to you to discuss in detail and resolve any issues.");
        return;
      }
      const base_url = "https://pmco1162s7.execute-api.us-east-1.amazonaws.com/prod";
      const body = {
        task: "submitMagicVerseSupportMsg",
        msg: description,
      };
      //console.log(body);
      try {
        const response = await fetch(base_url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        if(data.code===200)
          {alert(`Your message has been submitted. You'll receive an email(within 24 hours) responding to your request shortly.`);}
        else
          {alert(`Something went wrong. Please try again later.`);}
        //setGeneratedScript(data.script);
      } catch (err) {
        console.log(err.message);
      }

  };


  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
              <div>
      <h2>Contact Support:</h2>
      { (
        <div style={{ marginTop: '20px' }}>
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            rows="10"
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              resize: 'vertical',
            }}
          />

          <button
            onClick={handleSubmitSupportMessage}
            style={{
              marginTop: '10px',
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '5px',
              backgroundColor: '#28a745',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Submit Message
          </button>
        </div>
      )}
      </div>
      <SidebarNav />
    </div>
  );
};

export default Support;
