import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppRouter from './AppRouter';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-oidc-context";

const redirectUri = process.env.NODE_ENV === "production"
  ? "https://magicverse.me"
  : "http://localhost:3000";

const cognitoAuthConfig = {
  authority: "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_LAlMERydC",
  client_id: "63lshnga7pf7jc29penkr0jclf",
  redirect_uri: redirectUri,//"http://localhost:3000",
  response_type: "code",
  scope: "phone openid email",
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider {...cognitoAuthConfig}>
    <App />
    </AuthProvider>
  </React.StrictMode>
);
//<App /> --> replacing App with AppRouter
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
