// SidebarNavigation.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/SidebarNav.css';

const SidebarNav = () => {
  return (
    <div className="sidebar-navigation">
      <nav>
        <ul>
          <li>
            <Link to="/landing">Dashboard</Link>
          </li>
          <li>
            <Link to="/landing/VideoList">Created Videos</Link>
          </li> 
          <li>
            <Link to="/landing/subscription">Buy Credits</Link>
          </li>
          <li>
            <Link to="/landing/support">Support</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SidebarNav;
