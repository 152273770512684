import React, { useState,useEffect } from 'react';

const VideoScriptPage = () => {
  const [description, setDescription] = useState('Topic');
  const [generatedScript, setGeneratedScript] = useState('');
  const [isScriptGenerated, setIsScriptGenerated] = useState(false);
  const [language, setLanguage] = useState('English');
  const [withCaptions, setWithCaptions] = useState('With captions');
  const[quota, setQuota] = useState(0);
  
  useEffect(()=>{ 
    getUserInfo();
  }
  ,[]);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleGenerateScript = async () => {
    //call api and return script.
    const auth_user = await localStorage.getItem('auth_user');
    const id_token = JSON.parse(auth_user)?.id_token;
    //console.log(id_token);
    const headers = {
        Authorization: `Bearer ${id_token}`,
        "Content-Type": "application/json",
      };
      const base_url = "https://pmco1162s7.execute-api.us-east-1.amazonaws.com/prod";
      const body = {
        task: "getScriptFromTopic",
        topic: description,
        prompt:'',
      };
      try {
        const response = await fetch(base_url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }  
        const data = await response.json();
        //console.log(data);
        setGeneratedScript(data.script);
        setIsScriptGenerated(true);
      } catch (err) {
        console.log(err.message);
      }
    //const script = `This is a script based on the topic: "${description}". Please refine it further.`;
    //setGeneratedScript(script);

  };
  const getUserInfo = async () => {
    const auth_user = await localStorage.getItem('auth_user');
    const id_token = JSON.parse(auth_user)?.id_token;
    const headers = {
        Authorization: `Bearer ${id_token}`,
        "Content-Type": "application/json",
      };
      const base_url = "https://pmco1162s7.execute-api.us-east-1.amazonaws.com/prod";
      const body = {
        task: "getUserInfo",
      };
      try {
        const response = await fetch(base_url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }  
        const user_info = await response.json();
        //console.log(user_info);
        setQuota(user_info[0].quota);
        localStorage.setItem('user_info',JSON.stringify(user_info[0]));
      } catch (err) {
        console.log(err.message);
      }
  }

  const handleSubmitScript = async() => {
    //call api and show message on successful submission.
    const auth_user = await localStorage.getItem('auth_user');
    const id_token = JSON.parse(auth_user)?.id_token;
    const headers = {
        Authorization: `Bearer ${id_token}`,
        "Content-Type": "application/json",
      };
      //write code for counting words in generatedScript
      //console.log(generatedScript);
      const num_words = generatedScript.split(" ").length;
      const topic_char_count = description.length;
      //console.log(topic_char_count);
      //console.log(num_words);
      if (description === "" || topic_char_count > 20 || generatedScript === "" || num_words < 30 || num_words > 170)
      {
        alert("Please enter a valid topic and script before submitting. Topic Should be between 1 and 20 characters. Script should be between 40 and 170 words.");
        return;
      }
      const base_url = "https://pmco1162s7.execute-api.us-east-1.amazonaws.com/prod";
      const body = {
        task: "submitVideoGenRequest",
        script: generatedScript,
        topic: description,
      };
      //console.log(body);
      try {
        const response = await fetch(base_url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        //console.log(data);
        if(data.code===200)
          {alert(`Your script has been submitted. You'll receive an email (usually 5-10 minutes) when the video is ready.`);}
        else
          {alert(`Something went wrong. Please try again later.`);}
        //setGeneratedScript(data.script);
      } catch (err) {
        console.log(err.message);
      }

  };

  const handleScriptChange = (e) => {
    setGeneratedScript(e.target.value);
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <h2>Create Video Script</h2>
      <div style={{ marginBottom: '20px' }}>
      <label htmlFor="description" style={{ display: 'block', marginBottom: '8px', 
        fontWeight: 'bold',backgroundColor: '#fff', color: '#1e1a8f',padding: '10px', borderRadius: '5px'}}>
          Quota: {quota}
        </label>        
        <label htmlFor="description" style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>
          Topic or Video Description:
        </label>
        <input
          type="text"
          id="description"
          placeholder="Enter a topic or description..."
          value={description}
          onChange={handleDescriptionChange}
          style={{
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            borderRadius: '5px',
            border: '1px solid #ccc',
          }}
        />
      </div>
      <button
        onClick={handleGenerateScript}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          borderRadius: '5px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        Generate Script
      </button>

      { (
        <div style={{ marginTop: '20px' }}>
          <h3>Generate or Write Script</h3>
          <textarea
            value={generatedScript}
            onChange={handleScriptChange}
            rows="10"
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              resize: 'vertical',
            }}
          />

          <button
            onClick={handleSubmitScript}
            style={{
              marginTop: '10px',
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '5px',
              backgroundColor: '#28a745',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Submit Script for Video Creation
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoScriptPage;
