import axios from 'axios';
import Api from "../Api/Api.json";
import React, { useState,useEffect } from 'react';
//import BottomTab from './BottomTab';
import SidebarNav from './SidebarNav';
import VideoScriptPage from './VideoScriptPage';
import { Link, Route,Routes} from 'react-router-dom';

const SubscriptionPlan = () => {
  //localStorage.getItem('user_info')==[id,email,quota,verified]

  return (
    
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
     <SidebarNav />
    <div style={{  display: "flex", flexDirection: "column", padding: '20px', maxWidth: '600px', margin: 'auto',justifyContent: 'flex-end',backgroundColor: "#f9f9f9",}}>          
    <a href="https://buy.stripe.com/cN2bLu6410iJ77O3cd" target="_blank" rel="noopener noreferrer"
    style={{
      textDecoration: "none",
      color: "blue",
      fontWeight: "bold",
      textAlign:"center",
      marginBottom: "20px", // Adds spacing below the link
    }}>
    Buy Magicverse Credits
  </a>
        <ul style={{ display: "inline-block", padding: '20px',margin: 0 }}>
<p style={{ fontSize: "1rem", fontWeight: "bold" }}>Pricing:</p>
<li> You get 10 video minutes per dollar.</li>
<li> So buying $10 will give you 100 video minutes.</li>
<li> For additional video minutes, simply increase the number of products during checkout.</li>
<p style={{ fontSize: "1rem", fontWeight: "bold" }}>Payment Method:</p>
<li> Buy video creation credit through Stripe checkout.</li>
<li> Use the same email for payment as used in Magicverse login.</li>
<li> Reload dashboard to check your updated quota after the payment.</li>
        {/*
              <li key={3}>
                <Link to={`https://buy.stripe.com/28oaHq4ZX9Tjbo4288`}>plan: Corporate</Link>                                
                </li>*/}
          </ul>          
    </div>
    </div>
  
  );
};

export default SubscriptionPlan;
