import React from 'react';
import '../styles/FrontPage.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "react-oidc-context";
import ideaToVideoImage from "../images/ideaToVideo.jpeg";

function FrontPage() {
    const navigate = useNavigate();
    const auth = useAuth();
    const handleLoginClick = () => {
       // navigate('/login');
        auth.signinRedirect()
    }

    if (auth.isLoading) {
      return <div>Loading...</div>;
    }
  
    if (auth.error) {
      return <div>Encountering error... {auth.error.message}</div>;
    }
  
    if (auth.isAuthenticated) {
      localStorage.setItem('auth_user',JSON.stringify(auth.user));
      navigate('/landing');
      return (
        <div>
          <pre> Hello: {auth.user?.profile.email} </pre>
          <pre> ID Token: {auth.user?.id_token} </pre>
          <pre> Access Token: {auth.user?.access_token} </pre>
          <pre> Refresh Token: {auth.user?.refresh_token} </pre>
          <button onClick={() => auth.removeUser()}>Sign out</button>
        </div>
      );
    }

  return (

    <div className="front-page">
      {/* Header */}
      <header className="header">
        <div className="logo">MagicVerse: Your Story, Your Way</div>
        <nav className="nav">
          <a href="#features">Features</a>
          <a href="#pricing">Pricing</a>
          <a href="#features">FAQ</a>
          {/*<a href="#login" className="login-btn">Login</a>*/}
          <button onClick={handleLoginClick} className="login-btn">Login</button>

        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Transform Your Ideas Into Stunning Videos</h1>
          <p>Leverage AI to create professional-grade videos in minutes. No prior experience required! Just provide a topic or script and get your video.</p>
          <div className="hero-buttons">
            <button onClick={handleLoginClick} className="primary-btn">Get Started</button>
            {/* <button className="secondary-btn">Learn More</button> */}
            <a href="#footer">Find Us on Social Media</a>
          </div>
        </div>
        <div className="hero-image">
        <img src={ideaToVideoImage} style={{ width: "600px", height: "400px" }} alt="Video Creation" />
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="features">
        <h2>Why MagicVerse?</h2>
        <div className="features-grid">
          <div className="feature">
            <img src={ideaToVideoImage} style={{ width: "100px", height: "100px" }} alt="Easy to Use" />
            <h3>As Easy As 1-2-3</h3>
            <p>Provide topic or script, click generate video, sit back and get your video.</p>
          </div>
          <div className="feature">
            <img src={ideaToVideoImage} style={{ width: "100px", height: "100px" }} alt="Professional Quality" />
            <h3>High Quality Videos</h3>
            <p>Professional grade visual, audio and caption quality.</p>
          </div>
          <div className="feature">
            {/*<img src="https://via.placeholder.com/100" alt="Fast Rendering" />*/}
            <img src={ideaToVideoImage} style={{ width: "100px", height: "100px" }} alt="Human Review" />
            <h3> Human Review</h3>
            <p>Option for human review and editing if you ask for it.</p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="pricing" className="pricing">
        <h2>Pricing</h2>
        <div className="features-grid">
          <div className="feature">
          <img src={ideaToVideoImage} style={{ width: "100px", height: "100px" }} alt="Human Review" />
            <h3>Free Plan</h3>
            <p>Our main goal is to flourish your creativity. Free plan helps you to explore your ideas.</p>
          </div>
          <div className="feature">
          <img src={ideaToVideoImage} style={{ width: "100px", height: "100px" }} alt="Human Review" />
            <h3>Pay As You Go</h3>
            <p>We don't want you to pay for our services even when you're not using it. Thats why we offer a super transparent pay as you go plan.</p>
          </div>
          <div className="feature">
          <img src={ideaToVideoImage} style={{ width: "100px", height: "100px" }} alt="Human Review" />
            <h3>Monthly Subscription</h3>
            <p>If you believe that you are a heavy user and would like some additional discount or service, you can choose our monthly subscription.</p>
          </div>
        </div>
      </section>


      {/* Call-to-Action Section */}
      <section className="cta">
        <h2>Ready to Create Your First Video?</h2>
        <button onClick={handleLoginClick} className="primary-btn">Start Now</button>
      </section>

      {/* Footer */}
      <footer id="footer" className="footer">
        <p>© 2024 MagicVerse. All Rights Reserved.</p>
        <div className="social-links">
          <a href="https://www.tiktok.com/@magicversestories">Tiktok</a>
          <a href="https://www.youtube.com/@MagicVerseStories">Youtube</a>
          <a href="https://www.instagram.com/magicversestories/">Instagram</a>
        </div>
      </footer>
    </div>
  );
}

export default FrontPage;
